import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  getLanguageFromLocale,
  getSupportedLanguages,
  getSupportedLocales,
} from '@/helpers/i18n';

import { FooterStatus } from '@/store/app/models/FooterStatus';
import { LOCALE } from '@/store/app/models/Locale';
import { headerHeight } from '@/helpers/selector';

const isServer = typeof window === 'undefined';

const history = isServer ? createMemoryHistory() : createWebHistory();

const languageRegex = getSupportedLanguages()?.join('|');

const newsletterConfirmationPageImport = () => {
  import(
    /* webpackChunkName: "newsletter-confirmation-page" */ '../pages/NewsletterConfirmationPage.vue'
  );
};

const routes = (i18n: any): any[] => {
  const $translateRoute = (key: string, locale?: LOCALE): string => {
    const translation = i18n.global.t(`routes.${key}`, null, { locale });
    return translation === `routes.${key}` ? key : translation;
  };

  const routeRecords: any[] = [
    {
      path: `/:locale(${languageRegex})`,
      name: 'home-page',
      component: () =>
        import(/* webpackChunkName: "home" */ '../pages/HomePage.vue'),
    },
    {
      path: `/:locale(${languageRegex})?/:slug(.*)`,
      name: 'page',
      component: () => {
        return import(/* webpackChunkName: "page" */ '../pages/Page.vue');
      },
    },
    {
      path: `/:locale(${languageRegex})?/search`,
      name: 'search-page',
      component: () =>
        import(/* webpackChunkName: "search-page" */ '../pages/SearchPage.vue'),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    },
    {
      path: `/:locale(${languageRegex})?/ir-services`,
      name: 'newsletter-page',
      component: () =>
        import(
          /* webpackChunkName: "newsletter-page" */ '../pages/NewsletterPage.vue'
        ),
    },
    {
      path: `/en/confirmation-gdpr`,
      name: 'en-newsletter-confirmation-page',
      component: newsletterConfirmationPageImport,
    },
    {
      path: `/de/bestaetigung-gdpr`,
      name: 'de-newsletter-confirmation-page',
      component: newsletterConfirmationPageImport,
    },
    {
      path: `/:locale(${languageRegex})?/use-case/:slug`,
      name: 'use-case-page',
      component: () =>
        import(
          /* webpackChunkName: "use-case-page" */ '../pages/UseCasePage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.GROUP_FOOTER,
      },
    },
    {
      path: `/:locale(${languageRegex})?/corporate-governance/:slug`,
      name: 'corporate-governance-page',
      component: () =>
        import(
          /* webpackChunkName: "corporate-governance-page" */ '../pages/CorporateGovernancePage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    },

    {
      path: `/:locale(${languageRegex})?/career/:slug`,
      name: 'career-insight-page',
      component: () =>
        import(
          /* webpackChunkName: "career-insight-page" */ '../pages/CareerInsightPage.vue'
        ),
    },
    {
      path: `/:locale(${languageRegex})?/news/:id/:slug`,
      name: 'news-page',
      component: () =>
        import(/* webpackChunkName: "news-page" */ '../pages/NewsPage.vue'),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    },
    {
      path: `/:locale(${languageRegex})?/404`,
      name: 'not-found-page',
      component: () =>
        import(
          /* webpackChunkName: "not-found-page" */ '../pages/NotFoundPage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    },
    {
      path: `/:locale(${languageRegex})?/cookie-policy`,
      name: 'cookie-policy-page',
      component: () =>
        import(
          /* webpackChunkName: "cookie-policy-page" */ '../pages/CookiePolicyPage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    },
  ];

  // add dynamic routes with translations here
  getSupportedLocales().forEach((currentLocal: LOCALE) => {
    const language = getLanguageFromLocale(currentLocal);

    const news = encodeURIComponent($translateRoute('news', currentLocal));
    const markets = encodeURIComponent(
      $translateRoute('markets', currentLocal)
    );

    const newsDetailRoute = {
      path: `/:locale(${language})/${news}/:slug`,
      name: `${language}-news-detail-page`,
      component: () =>
        import(
          /* webpackChunkName: "news-detail-page" */ '../pages/NewsDetailPage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    };

    const expertiseMarketRoute = {
      path: `/:locale(${languageRegex})?/expertise/${markets}`,
      name: `${language}-expertise-markets`,
      component: () =>
        import(
          /* webpackChunkName: "expertise-markets-page" */ '../pages/MarketsPage.vue'
        ),
      meta: {
        defaultFooter: FooterStatus.FOOTER,
      },
    };

    routeRecords.push(...[expertiseMarketRoute, newsDetailRoute]);
  });

  return routeRecords;
};

export default function (i18n: any) {
  return createRouter({
    scrollBehavior(to: any, from: any) {
      const { avoidScroll } = to.params;
      if (typeof avoidScroll === 'string' && JSON.parse(avoidScroll)) {
        return;
      }

      if (to.hash && to.path === from.path) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ el: to.hash, behavior: 'smooth', top: headerHeight() });
          }, 750);
        });
      }
      return { el: '#app', x: 0, y: 0 };
    },
    routes: routes(i18n),
    history,
  });
}
